@use "../util" as *;

.event-gallery {
  background: $bg-4;
  padding: 55px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
  width: 100%;
  &__title {
    font-size: 24px;
    line-height: 39px;
    font-weight: 800;
    color: $text-13;
    margin-bottom: 40px;
  }
  &__slides {
    position: relative;
    display: flex;
    align-items: center;
    gap: 25px;
  }
  &__slide {
    width: 95px;
    height: 95px;
    padding: 2px;
    background: $bg-2;
    border: 1px solid $border-3;
    &.slide1,
    &.slide2,
    &.slide3 {
      display: none;
    }
  }
  &__arrow--left,
  &__arrow--right {
    font-size: 30px;
    line-height: 39px;
    color: $text-10;
    position: absolute;
    cursor: pointer;
  }
  &__arrow--left {
    transform: translateX(-100%);
    left: -15px;
  }
  &__arrow--right {
    transform: translateX(100%);
    right: -15px;
  }
}

@media screen and (min-width: $bp-tablet) {
.event-gallery {
   padding: 75px 30px;
   &__title {
      font-size: 30px;
    }
   &__slides {
      gap: 30px;
    }
    &__slide {
      width: 130px;
      height: 130px;
      &.slide2,
      &.slide3 {
        display: block;
      }

    }
    &__arrow--left,
    &__arrow--right {
      font-size: 40px;
    }
    &__arrow--left {
      transform: translateX(-100%);
      left: -32px;
    }
    &__arrow--right {
      transform: translateX(100%);
      right: -32px;
    }
   }

}

@media screen and (min-width: $bp-desktop) {
   .event-gallery {
    margin-bottom: 92px;
      &__title {
         font-size: 30px;
       }
      &__slides {
         gap: 30px;
       }
       &__slide {
         width: 164px;
         height: 164px;
         &.slide1,
         &.slide2,
         &.slide3 {
           display: block;
         }
   
       }
       &__arrow--left,
       &__arrow--right {
         font-size: 40px;
       }
       &__arrow--left {
         transform: translateX(-100%);
         left: -71px;
       }
       &__arrow--right {
         transform: translateX(100%);
         right: -71px;
       }
      }
}
