@use "../util" as *;

.about-section1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 70px;
  padding: 0 20px;
  position: relative;
  &__bg--images {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    img {
      position: absolute;
      display: none;
    }
    .gold-number-mobile {
      display: block;
      left: -65%;
      top: -5%;
    }
    
  }
  &__title {
    font-size: 32px;
    line-height: 43px;
    color: $text-1;
    font-weight: 300;
    text-align: center;
    max-width: 165px;
    z-index: 1;
    b {
      font-weight: 500;
    }
  }
  &__separator {
    width: 70px;
    height: 2px;
    background: $accent-1;
    margin: 25px 0;
    z-index: 1;
  }
  &__text {
    font-size: 14px;
    line-height: 20px;
    color: $text-1;
    font-weight: 300;
    text-align: center;
    margin-bottom: 60px;
    z-index: 1;
  }
  &__card--grid {
    display: flex;
    flex-direction: column;
    gap: 25px;
    z-index: 1;
    width: 100%;
  }
  &__card {
    background: $bg-3;
    border-radius: $radius-sm;
    padding: 40px 30px;
    position: relative;
    &--title {
      font-size: 20px;
      line-height: 25px;
      font-weight: 700;
      color: $text-1;
      max-width: 140px;
    }
    &--separator {
      width: 50px;
      height: 2px;
      background: $accent-1;
      margin: 20px 0;
    }
    &--text {
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
      color: $text-1;
      max-width: 300px;
      b {
        font-weight: 500;
      }
    }
    &--icon {
      width: 55px;
      position: absolute;
      right: 0;
      top: 20px;
      &.to-left {
        right: 17px;
      }
    }
  }
}

@media screen and (min-width: $bp-tablet) {
  .about-section1 {
    &__bg--images {
      .gold-number-mobile {
        display: none;
      }
      .gold-number-tablet {
        display: block;
        left: -40%;
        top: -10%;
      }

      .green-vector-tablet {
        display: block;
        right: -10%;
        top: -40%;
      }
    }
    &__title {
      max-width: 100%;

      b {
        font-weight: 500;
      }
    }
    &__text {
      line-height: 22px;
    }
    &__card {
      background: $bg-3;
      border-radius: $radius-sm;
      padding: 40px 40px;
      position: relative;
      &--title {
        font-size: 25px;
        line-height: 30px;
        max-width: 170px;
      }
      &--icon {
        top: 28px;
        &.to-left {
          right: 26px;
        }
      }
    }
  }

  @media screen and (min-width: $bp-desktop) {
    .about-section1 {
      &__bg--images {
        .gold-number-tablet {
          display: none;
        }
        .gold-number-desktop {
          display: block;
          left: -35%;
          top: -20%;
        }

        .green-vector-tablet {
          display: none;
        }
        .green-vector-desktop {
          display: block;
          right: -10%;
          top: -40%;
        }
      }
      margin-bottom: 80px;
      &__title {
        font-size: 35px;
      }
      &__text {
        max-width: 700px;
        margin-bottom: 32px;
      }
      &__card--grid {
        width: 100%;
        max-width: 1015px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 20px;
        & .card1 {
          grid-row: span 2;
          grid-column: span 1;
          .about-section1__card--icon {
            width: 212px;
            top: unset;
            right: unset;
            bottom: 25px;
            left: 22px;
          }
        }
        & .card2 {
          grid-row: span 1;
          grid-column: span 2;
          .about-section1__card--text {
            max-width: 400px;
          }
          .about-section1__card--icon {
            width: 100px;
          }
        }
        & .card3 {
          grid-row: 2;
          grid-column: 2;
          .about-section1__card--icon {
            width: 72px;
          }
        }
        & .card4 {
          grid-row: 2;
          grid-column: 3;
          .about-section1__card--icon {
            width: 62px;
          }
        }
      }
      &__card {
        padding: 56px 50px;
        position: relative;
        &--title {
          font-size: 25px;
          line-height: 30px;
        }
        &--text {
          font-size: 16px;
          max-width: unset;
        }
      }
    }
  }
}
