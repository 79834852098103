@use "../util" as *;

.user-form {
  width: 100%;
  max-width: 825px;
  padding: 0 30px;
  padding-bottom: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__steps {
    width: 100%;
    max-width: 450px;
    &--step {
      font-size: 14px;
      line-height: 14px;
      font-weight: 700;
      color: $text-11;
      margin-bottom: 27px;
      &-title {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 21px;
        margin-left: 5px;
      }
    }
    &--icon {
      font-size: 12px;
      line-height: 12px;
      color: $text-1;
      width: 21px;
      height: 21px;
      background: $success-4;
      border-radius: $rounded;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $bg-1;
      box-shadow: 0 0 0 1px $success-4;
    }
    &--progress {
      width: 100%;
      height: 5px;
      border-radius: $radius-xs;
      background: $bg-11;
      overflow: hidden;
    }
    &--bar {
      width: 0;
      height: 100%;
      background: $success-4;
      transition: all 0.6s ease;
    }
  }

  &__form {
    width: 100%;
    max-width: 450px;
  }

  &__field {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    label,
    input {
      font-size: 14px;
      line-height: 16.5px;
      font-weight: 700;
      color: $text-1;
      margin-bottom: 9px;
    }
    &.invalid input {
      border: 1px solid $danger-2;
      background: $bg-2;
    }
    label {
      margin-bottom: 10px;
    }
    input {
      color: $text-3;
      border: 1px solid $border-4;
      background: $bg-10;
      padding: 10px 5px;
      width: 100%;
      &::placeholder {
        color: $text-9;
        font-weight: 400;
      }
      &:focus {
        background: $bg-2;
        outline: none;
      }

      &.valid {
        border: 1px solid $success-4;
        background: $bg-2;
      }
      &[type='date'] {
      width: 100%;
      height: 39px;
      -webkit-min-logical-width: calc(100% - 16px);
      }
    }
    &:last-of-type {
      margin-bottom: 30px;
    }
  }

  &__errors {
    div {
      position: relative;
      margin-left: 15px;
      font-size: 12px;
      line-height: 14px;
      color: $danger-2;
      &::before {
        content: "";
        position: absolute;
        left: -15px;
        top: 50%;
        transform: translateY(-50%);
        width: 9px;
        height: 9px;
        border-radius: $rounded;
        border: 3px solid $danger-2;
      }
    }
    &:last-child div {
      color: $text-10;
      &::before {
        border-color: $text-10;
      }
    }
  }
  &__valid {
    color: $success-4 !important;
    &::before {
      border-color: $success-4 !important;
    }
  }
  &__invalid {
    color: $danger-2 !important;
    &::before {
      border-color: $danger-2 !important;
    }
  }

  // DEFAULT BUTTON
  &__submit--button {
    // text styling
    font-family: $font-bebas-neue;
    font-size: 24px;
    line-height: 27.5px;
    font-weight: 400;
    color: $text-1;
    width: 100%;
    justify-content: center;

    // button styling
    padding: 6px 82px;
    background-color: $accent-1;
    transition: opacity 0.3s ease;
    &:hover {
      opacity: 0.7;
    }
    &.disabled {
      background: $bg-12;
      user-select: none;
      cursor: not-allowed;
      &:hover {
        opacity: 1;
      }
    }
  }
  //  ---------------

  &__popup {
    background: $success-2;
    color: $success-5;
    border: 1px solid $success-3;
    padding: 20px 15px;
    padding-bottom: 30px;
    &--title {
      font-size: 20px;
      line-height: 28px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      &-icon {
        font-size: 32px;
        color: $success-3;
        margin-right: 8px;
      }
    }
    &--text {
      font-size: 14px;
      line-height: 16.5px;
      display: flex;
      align-items: flex-start;
      &-icon {
        font-size: 19px;
        margin-right: 5px;
      }
    }
  }

  &__register {
    font-size: 14px;
    line-height: 15.5px;
    font-weight: 300;
    color: $text-1;
    text-align: center;
    margin: 25px 0;
    &--button {
      color: $accent-2;
      text-decoration: underline;
      display: inline-flex;
    }
  }
  &__terms--conditions {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: $text-8;
    text-align: center;
    padding: 0 30px;
    &--link {
      color: $accent-2;
    }
    span {
      color: $text-7;
    }
  }
}

@media screen and (max-width: $bp-tablet) {
  .user-form {
    &__steps {
      &--step {
        display: none;
        &.active {
          display: block;
        }
      }
    }
  }
}

@media screen and (min-width: $bp-tablet) {
  .user-form {
    &__steps {
      display: flex;
      gap: 8px;
      &--step {
        flex: 1;
        margin-bottom: 40px;
      }
    }
  }
}
@media screen and (min-width: $bp-desktop) {
  .user-form {
    &__steps {
      display: flex;
      gap: 20px;
      max-width: 800px;
      &--step {
        flex: 1;
      }
    }
    &__form {
      max-width: 800px;
    }
    &__fields--wrapper {
      display: flex;
      gap: 20px;
      margin-bottom: 40px;
    }
    &__field {
      width: 100%;
      margin-bottom: 0;
      &:last-of-type {
        margin-bottom: 0;
        input {
          padding: 9px 5px;
        }
      }
    }
    &__submit--button {
      max-width: 345px;
      margin: 0 auto;
    }
    &__popup {
      padding: 47px 31px;
      padding-bottom: 62px;
      &--title {
        margin-bottom: 32px;
        font-size: 24px;
        &-icon {
          margin-right: 12px;
          font-size: 52px;
        }
      }
      &--text {
        &-icon {
          margin-right: 10px;
        }
      }
    }
  }
}
