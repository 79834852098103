@use "../util" as *;

.banner__wrapper {
  background: $bg-gradient-1;
  @include center(column);
  text-align: center;
  width: 100%;
  overflow-x: hidden;
}

.banner {
  padding: 84px 6px;
  position: relative;
  &__wrapper--inner {
    z-index: 1;
    position: relative;
    @include center(column);
  }
  &__intersect {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    &--desktop1,
    &--tablet1,
    &--mobile1 {
      width: 200px;
      position: absolute;
      top: 0;
      left: 0;
      display: none;
    }
    &--desktop2,
    &--tablet2,
    &--mobile2 {
      width: 200px;
      position: absolute;
      right: 0;
      bottom: 0;
      display: none;
    }
    &--mobile1,
    &--mobile2 {
      display: block;
    }
  }

  &__general--swap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    &-desktop,
    &-tablet,
    &-mobile {
      height: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: none;
    }
    &-mobile {
      display: unset;
      height: auto;
      width: 125%;
      top: 0;
      transform: translate(-45%, 0%);
    }
  }
  &__subtext {
    line-height: 39.5px;
    font-weight: 400;
    letter-spacing: 1px;
    color: $text-2;
    margin-bottom: 20px;
  }
  &__title {
    font-size: 35px;
    line-height: 38px;
    font-weight: 400;
    color: $text-1;
    width: 100%;
    max-width: 620px;
  }
  &__text {
    font-size: 14px;
    line-height: 22px;
    font-weight: 300;
    color: $text-1;
    margin: 30px 0;
    max-width: 460px;
  }
  // DEFAULT BUTTON
  &__account--button {
    // text styling
    font-family: $font-bebas-neue;
    font-size: 24px;
    line-height: 27.5px;
    font-weight: 400;
    color: $text-1;

    // button styling
    padding: 6px 0;
    width: 100%;
    max-width: 290px;
    justify-content: center;
    background-color: $accent-1;
    transition: opacity 0.3s ease;
    &:hover {
      opacity: 0.7;
    }
  }
  //  ---------------
  &__demo {
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    color: $text-1;
    margin-top: 15px;
    margin-bottom: 15px;
    &--link {
      color: $text-1;
      text-decoration: underline;
    }
  }
  &__terms--conditions {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: $text-1;
    padding: 0 38px;
    &--link,
    span {
      color: $accent-2;
    }
  }
}

@media screen and (min-width: $bp-tablet) {
  .banner {
    width: $container-tablet;
    padding: 100px 0;
    &__general--swap {
      &-mobile {
        display: none;
      }
      &-tablet {
        display: block;
      }
    }
    &__intersect {
      &--mobile1,
      &--mobile2 {
        display: none;
      }
      &--tablet1,
      &--tablet2 {
        display: block;
        width: 300px;
      }
    }
    &__subtext {
      margin-bottom: 25px;
    }
    &__title {
      max-width: 500px;
    }

    &__text {
      margin: 25px 0;
      max-width: 400px;
    }
    &__account--button {
      max-width: 350px;
    }
    &__demo {
      margin-top: 25px;
      margin-bottom: 15px;
    }
  }
}

@media screen and (min-width: $bp-desktop) {
  .banner {
    width: $container-desktop;
    padding-top: 120px;
    padding-bottom: 160px;
    &__general--swap {
      &-tablet {
        display: none;
      }
      &-desktop {
        display: block;
      }
    }
    &__intersect {
      &--tablet1,
      &--tablet2 {
        display: none;
      }
      &--desktop1,
      &--desktop2 {
        display: block;
        width: 400px;
      }
    }
    &__title {
      max-width: 650px;
    }
    &__text {
      font-size: 16px;
      max-width: 460px;
    }
  }
}
