@use "../util" as *;


*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font-roboto;
}

body {
  display: flex;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
}

a,
a:visited,
a:active {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  line-height: 100%;
}

button {
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
}

#root {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  height: 100%;;
}

// accessibility for icons
.visually-hidden {
  clip: rect(0 0 0 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px;
}

.main {
  width: 100%;
  overflow-x: hidden;
   display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  &__inner--wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $bg-1;
    width: 100%;
  }
  &__inner--container {
    width: $container-mobile;
  }

}

@media screen and (min-width: $bp-tablet) {
  .main {
    width: 100%;
    &__inner--container {
      width: $container-tablet;
    }
  
  }
}

@media screen and (min-width: $bp-desktop) {
  .main {
    width: 100%;
    &__inner--container {
      width: $container-desktop;
    }
  
  }
}