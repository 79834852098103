@use "../util" as *;

.about-section2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-bottom: 70px;
  padding: 0 15px;
  &__bg--cross {
    display: none;
  }
  &__title--wrapper {
    margin-bottom: 65px;
  }
  &__separator {
    width: 40px;
    height: 4px;
    background: $accent-2;
    margin: 0 auto;
    margin-bottom: 22px;
  }
  &__title {
    font-size: 35px;
    line-height: 48px;
    color: $text-5;
    font-weight: 900;
    margin-bottom: 23px;
    span {
      color: $accent-2;
    }
  }
  &__text {
    font-size: 14px;
    line-height: 21px;
    color: $text-5;
    font-weight: 400;
  }
  &__tile--grid {
    text-align: center;
  }
  &__tile {
    margin-bottom: 47px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &--icon {
      width: 45px;
      margin-bottom: 18px;
    }
    &--title {
      font-size: 20px;
      line-height: 23.5px;
      font-weight: 700;
      color: $text-5;
      margin-bottom: 15px;
    }
    &--text {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: $text-5;
    }
  }
}

@media screen and (min-width: $bp-tablet) {
  .about-section2 {
    margin-bottom: 77px;
    &__bg--cross {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      @include center(row);
      & > div {
        position: absolute;
      }
      &-hl1 {
        width: 480px;
        height: 0.5px;
        background: $bg-13;
      }
      &-hl2 {
        width: 22px;
        height: 0.5px;
        background: $bg-14;
      }
      &-vl1 {
        width: 0.5px;
        height: 220px;
        background: $bg-13;
      }
      &-vl2 {
        width: 0.5px;
        height: 22px;
        background: $bg-14;
      }
      &-dot {
        width: 3px;
        height: 3px;
        background: $accent-2;
        border-radius: $rounded;
        margin-top: 1px;
      }
    }
    &__title--wrapper {
      margin-bottom: 53px;
    }

    &__title {
      font-size: 42px;
    }
    &__separator {
      margin-bottom: 25px;
    }
    &__tile--grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 58px;
      column-gap: 130px;
      padding: 0 85px;
      position: relative;
    }
    &__tile {
      margin-bottom: 0;
      text-align: start;
      padding-bottom: 20px;
    }
  }
}

@media screen and (min-width: $bp-desktop) {
  .about-section2 {
    margin-bottom: 90px;
    flex-direction: row;
    justify-content: center;
    gap: 115px;
    &__bg--cross {
      &-hl1 {
        width: 508px;
      }
      &-vl1 {
        height: 235px;
      }
    }
    &__title--wrapper {
      margin-bottom: 55px;
      max-width: 300px;
    }

    &__title {
      font-size: 80px;
      line-height: 90px;
    }
    &__tile--grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 58px;
      column-gap: 130px;
      padding: 0 0 !important;
      padding-left: 0px !important;
      max-width: 700px;
    }
    &__separator {
      width: 72px;
      margin-bottom: 40px;
      margin-left: 0;
    }
    &__tile--grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 85px;
      column-gap: 175px;
      padding: 0 85px;
    }
    &__title--wrapper {
      text-align: start;
    }
  }
}
